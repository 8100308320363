@import "../../../styles/layout/color.scss";
.downloadbtn {
  background-color: $Lemongreen;
  border: 1px solid $Lemongreen;
  border-radius: 3px;
  font: 14px bold;
  padding: 6px 20px;
  cursor: pointer;
&:hover
{
    background-color: $Lemongreen;
}
}

.form-select:focus {
  border-color: $formBorderGrey;
  outline: 0;
  box-shadow: none;
}

.fileBasedBatch {
  
  .customDatepicker {
    width: 100%;
    height: 48px;
    border: 1px solid $formBorderGrey;
    border-radius: 0.375rem;
    padding-left: 10px;
    outline: none;
    background: url("../../../Images/calendar.svg") no-repeat $white;
    background-size: 20px;
    background-position: 97% 50%;
  }
  .customDatepicker:focus {
    border: 1px solid $placeHolder;
  }
  .loaderDivTable{
    width: 100%;
    position: absolute;
    height: 100%;
    max-height: 25rem;
    display: flex;
    justify-content: center;
    z-index: 999;
    background-color: $GhostWhite;
  }
  &_fileBasedTxt {
    color: $DuskBlue;
    font-size: 16px !important;
  }
  &_api-batch-table {
    table {
      thead {
        th {
          font-weight: 500;
          vertical-align: middle;
          text-align: center !important;
        }
        th:first-child {
          padding-left: 15px !important;
          padding-right: 15px !important;
          min-width: max-content !important;
        }
      }
      .id,.user_name,.bank_code,.company_code,.file,.user_name,.upload_date_time,.download
      {
        text-align: center !important;
      }
      .idData {
        min-width: max-content !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}