@import "../../styles//layout/color.scss";
.PartnerPayments {
  &_DisbursmentCaseWrapper {
    position: relative;
    min-height: 39rem;
    border: 1px solid $grey;
    &_Table-Head {
      min-height: 54px;
      display: flex !important;
      border-radius: 10px 0rem 0rem 0rem;
      background-color: $navy-blue;
      padding: 0.5rem 8%;
      width: 100%;
      p {
        color: $white !important;
        position: relative;
        top: 0.6rem;
        font-weight: 500;
      }
    }
  }
  &_DisbursmentCaseWrapper {
    border-top-left-radius: 10px;
    &_bottomButtons {
      position: absolute;
      bottom: 1rem;
      button {
        background-color: $Lemongreen !important;
        color: black;
        border: 0;
        padding: 6px 2rem;
      }
      &_outlineBtn {
        button {          
          background-color: $white !important;
          border: 1px solid $Lemongreen;
          padding: 6px 2.5rem;
        }
      }
    }
  }
}

.manage-inputs {
  padding: 0px;
  border: 1px solid $grey;
  border-radius: 5px;
  opacity: 1;
  min-width: 100%;
  min-height: 40px;
}
